import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Siaran Langsung', 'Live Wedding'),
  titlePrewedding: get('Prewedding', 'Prewedding'),
  desc: get(
    `Siaran langsung juga dapat disaksikan <br />melalui platform youtube:`,
    `Live wedding can also be watched <br />via the Youtube platform:`,
  ),
  textButton: get('Buka via Youtube', 'Open via Youtube'),
};