import { BG_SECONDARY, REVERSE_ELIPS_COLOR } from '@/constants/colors';
import { Box } from '@chakra-ui/react';
import { bool } from 'prop-types';
import React from 'react';

function Wave({ isElips, ...rest }) {
  let wave = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="49px"
      viewBox="0 0 1280 140"
      preserveAspectRatio="none"
    >
      <g fill={BG_SECONDARY}>
        <path
          d="M1280 3.4C1050.59 18 1019.4 84.89 734.42 84.89c-320 0-320-84.3-640-84.3C59.4.59 28.2 1.6 0 3.4V140h1280z"
          fillOpacity=".3"
        />
        <path
          d="M0 24.31c43.46-5.69 94.56-9.25 158.42-9.25 320 0 320 89.24 640 89.24 256.13 0 307.28-57.16 481.58-80V140H0z"
          fillOpacity=".5"
        />
        <path d="M1280 51.76c-201 12.49-242.43 53.4-513.58 53.4-320 0-320-57-640-57-48.85.01-90.21 1.35-126.42 3.6V140h1280z" />
      </g>
    </svg>
  );
  if (isElips) {
    wave = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill={REVERSE_ELIPS_COLOR}
          d="M0 32l120 32c120 32 360 96 600 101.3 240 5.7 480-48.3 600-74.6L1440 64V0H0z"
        />
      </svg>
    );
  }
  return (
    <Box minHeight="40px" width="100%" {...rest}>
      {wave}
    </Box>
  );
}

Wave.propTypes = {
  isElips: bool,
};

Wave.defaultProps = {
  isElips: false,
};

export default React.memo(Wave);
