import React from 'react';
import { Box, Center, Text, Link, Heading, IconButton, Divider } from '@chakra-ui/react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';
import Wave from '@components/Common/Wave';

import WishesSection from '@components/Section/Wishes';
import GiftCard from '@components/Section/GiftCard';
import useInvitation from '@/hooks/useInvitation';

import {
  DEFAULT_LANGUAGE as lang,
  MUSLIM_INVITATION,
  ENABLE_COLLABORATION,
  ENABLE_VIDEO_PREWEDDING,
  ENABLE_LIVE_STREAMING,
  ENABLE_GIFT_CARD,
  SELECTED_VENDOR_COLLABORATION,
} from '@/constants/features-flag';
import { THE_BRIDE, SOUND_BY, URL_WA_INVITATO, URL_IG_INVITATO, SOUND_URL } from '@/constants';
import { BG_WISHLIST, INVITATO_LOGO } from '@/constants/assets';
import { BG_SECONDARY_DARK, BG_SECONDARY_TRANSPARENT } from '@/constants/colors';

import * as ID from '@/constants/identifier';
import txtWording from './locales';

function CopyRightSection() {
  const isInvitation = useInvitation();

  return (
    <Box>
      <Box bgColor="theme.bgSecondary">
        <Box
          background={`url(${BG_WISHLIST})`}
          backgroundSize="cover"
          backgroundPosition="center"
          boxShadow={`inset 0 0 0 2000px ${BG_SECONDARY_TRANSPARENT}`}
          padding="32px 0 32px"
        >
          <WishesSection inverse />
        </Box>
        {ENABLE_LIVE_STREAMING && (
          <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
        )}
        {ENABLE_VIDEO_PREWEDDING && (
          <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
        )}
        {!ENABLE_GIFT_CARD && (
          <Wave style={{ backgroundColor: 'transparent', transform: 'translateY(-38px)' }} />
        )}
        {!ENABLE_LIVE_STREAMING && !ENABLE_VIDEO_PREWEDDING && ENABLE_GIFT_CARD && (
          <>
            <GiftCard lang={lang} name={ID.ID_GIFT_SECTION} />
            <Center>
              <Divider
                margin="-2px 0 12px"
                maxWidth="70%"
                height="1px"
                bgColor="mainColorTextLight"
                color="mainColorTextLight"
                border="none"
                boxShadow="none"
                borderBottomWidth="none"
              />
            </Center>
          </>
        )}
        <Box p={4}>
          <WithAnimation>
            <Text
              align="center"
              color="mainColorTextLight"
              fontSize="sm"
              marginTop={
                !ENABLE_LIVE_STREAMING && !ENABLE_VIDEO_PREWEDDING && ENABLE_GIFT_CARD
                  ? ''
                  : '-24px'
              }
            >
              {isInvitation ? (
                <>{txtWording.closingInvitation[lang]}</>
              ) : (
                <>{txtWording.closingAnnouncement[lang]}</>
              )}
              {MUSLIM_INVITATION && (
                <>
                  <br />
                  Wassalamualaikum Warahmatullahi Wabarakatuh.
                </>
              )}
            </Text>
          </WithAnimation>
          <Center>
            <WithAnimation>
              <Heading fontSize="3xl" color="mainColorTextLight" margin="24px 0">
                {THE_BRIDE}
              </Heading>
            </WithAnimation>
          </Center>
        </Box>
      </Box>
      <Box bgColor={BG_SECONDARY_DARK}>
        <Box padding="16px 32px 16px 32px">
          <Center>
            <Link href={URL_IG_INVITATO} target="_blank">
              <Image src={INVITATO_LOGO} maxWidth="120px" />
            </Link>
          </Center>
          <Text color="mainColorTextLight" fontSize="xs" textAlign="center">
            Created with Love by Invitato
          </Text>
          {/* Icon IG & WA Not Used */}
          {false && (
            <WithAnimation>
              <Center>
                <Link href={URL_IG_INVITATO} target="_blank">
                  <IconButton
                    isRound
                    size="sm"
                    variant="outline"
                    background="mainColorText"
                    border="none"
                    icon={<FaInstagram color="white" size="20px" />}
                    marginRight="4px"
                  />
                </Link>
                <Link href={URL_WA_INVITATO} target="_blank">
                  <IconButton
                    isRound
                    size="sm"
                    variant="outline"
                    icon={<FaWhatsapp color="white" size="20px" />}
                    marginLeft="4px"
                    background="mainColorText"
                    border="none"
                  />
                </Link>
              </Center>
            </WithAnimation>
          )}
          <WithAnimation>
            <Box>
              {/* Collaboration Vendor */}
              {ENABLE_COLLABORATION && (
                <Box margin="-4px 0 8px">
                  <Center>
                    <Divider
                      margin="16px 0"
                      maxWidth="30%"
                      height="1px"
                      bgColor="mainColorTextLight"
                      color="mainColorTextLight"
                      border="none"
                      boxShadow="none"
                      borderBottomWidth="none"
                    />
                  </Center>
                  <Center>
                    <Link
                      href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`}
                      target="_blank"
                    >
                      <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                    </Link>
                  </Center>
                  <Center>
                    <Text color="mainColorTextLight" align="center" fontSize="xs">
                      In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                    </Text>
                  </Center>
                </Box>
              )}
              {/* Copy Right */}
              <Center marginTop={ENABLE_COLLABORATION ? '12px' : '12px'}>
                <Text textAlign="center" color="mainColorTextLight" fontSize="xs">
                  {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Rights Reserved`}
                </Text>
              </Center>
              <Center>
                <Link
                  textAlign="center"
                  href={SOUND_URL}
                  color="mainColorTextLight"
                  fontSize="xs"
                  isExternal
                >
                  {`Song by ${SOUND_BY}`}
                </Link>
              </Center>
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(CopyRightSection);
