import React from 'react';
import { FaGlassCheers } from 'react-icons/fa';
import { string } from 'prop-types';
import { Box, Center, Text, Heading } from '@chakra-ui/react';

import CountDown from '@components/Common/CountDown';
import WithAnimation from '@components/Common/WithAnimation';
// import IconWeddingRing from '@components/Common/Icons/WeddingRing';

import useInvitation from '@/hooks/useInvitation';
import useShiftTime from '@/hooks/useShiftTime';

import WeddingLocation from './WeddingLocation';
import WeddingDetail from './WeddingDetail';
import txtWording from './locales';

import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';
import { BG_PATTERN, BG_WEDDING_DETAIL } from '@/constants/assets';
import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_FULLDATE,
} from '@/constants';

/**
 * function to render WeddingSectionV4 component
 * @returns {JSX.Element}
 */
function WeddingSectionV4({ color, ...rest }) {
  const isInvitation = useInvitation();
  const time = useShiftTime();

  return (
    <Box backgroundImage={`url(${BG_PATTERN})`} padding="32px 16px" {...rest}>
      <Box>
        <Box boxShadow="2xl" backgroundColor="mainColor" borderRadius="2xl" padding="36px 32px">
          <WithAnimation>
            <Center>
              <Heading
                fontSize="3xl"
                color="mainColorTextLight"
                fontWeight="bold"
                align="center"
                fontFamily="fantasy"
              >
                {txtWording.title[lang]}
              </Heading>
            </Center>
          </WithAnimation>
          <WithAnimation>
            <Text color="mainColorTextLight" align="center">
              {txtWording.subTitle[lang]}
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Center margin="12px 0">
              <CountDown lang={[lang]} />
            </Center>
          </WithAnimation>
          <Box
            backgroundImage={`url(${BG_WEDDING_DETAIL})`}
            backgroundSize="cover"
            backgroundPosition="center"
            borderRadius="16px"
            padding="16px"
            marginTop="16px"
            boxShadow="inset 0 0 0 2000px rgb(94 71 53 / 50%)"
          >
            <WithAnimation>
              <Box>
                <WeddingDetail
                  color={color}
                  title={txtWording.receptionName[lang]}
                  titleProps={{ color }}
                  // Icon={<IconWeddingRing height="50px" width="50px" color={color} />}
                  Icon={<FaGlassCheers size="40px" color={color} />}
                  subTitle={
                    <>
                      {!isInvitation &&
                        <>
                          <Text margin="6px 0 6px">{WEDDING_AKAD_FULLDATE}</Text>
                        </>
                      }
                      {isInvitation &&
                        <>
                          <Text margin="6px 0 6px">{WEDDING_AKAD_TIME}</Text>
                          <Text marginBottom="6px">{WEDDING_AKAD_FULLDATE}</Text>
                          <Text marginBottom="6px">{WEDDING_AKAD_LOC_NAME}</Text>
                          <Text>{WEDDING_AKAD_LOC_ROAD}</Text>
                        </>
                      }
                    </>
                  }
                />
              </Box>
            </WithAnimation>
            {isInvitation && false && (
              <WithAnimation>
                <Box>
                  <WeddingDetail
                    color={color}
                    title={txtWording.receptionName[lang]}
                    titleProps={{ color }}
                    Icon={<FaGlassCheers size="40px" color={color} />}
                    subTitle={
                      <>
                        <span>{time}</span>
                        <br />
                        <span>{WEDDING_RESEPSI_FULLDATE}</span>
                        <br />
                        <span>{WEDDING_RESEPSI_LOC_NAME}</span>
                        <br />
                        <span>{WEDDING_RESEPSI_LOC_ROAD}</span>
                      </>
                    }
                  />
                </Box>
              </WithAnimation>
            )}
            <Box>
              <WithAnimation>
                <WeddingLocation
                  color={color}
                  withIcon={false}
                  withRoad={false}
                  withLocation={false}
                />
              </WithAnimation>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

WeddingSectionV4.propTypes = {
  color: string,
};

WeddingSectionV4.defaultProps = {
  color: 'white',
};

export default React.memo(WeddingSectionV4);
