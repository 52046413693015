import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import DetailCard from './DetailCard';
import useInvitation from '@/hooks/useInvitation';
import txtWording from './locales';

import { IMG_MAN, IMG_GIRL } from '@/constants/assets';
import {
  BOY_PARENT_NAME,
  GIRL_PARENT_NAME,
  IG_BOY,
  IG_GIRL,
  BOY_FULL_NAME,
  GIRL_FULL_NAME,
  IS_BOY_FIRST,
} from '@/constants';
import { DEFAULT_LANGUAGE as lang, MUSLIM_INVITATION } from '@/constants/features-flag';

const HEIGHT_OVERFLOW = '50px';
const COMMON_OPTIONS = {
  fullNameProps: { fontSize: '26px', padding: '8px 0 4px 0' },
  instagramButton: { onlyIcon: true, size: 'xs' },
};

function CoupleInfo({ ...rest }) {
  const isInvitation = useInvitation();

  return (
    <Box {...rest}>
      <Box>
        <WithAnimation>
          <Text align="center" fontSize="small" color="mainColorText" padding="24px 16px">
            {isInvitation
              ? 
                <>
                  {MUSLIM_INVITATION ? txtWording.openingInvitationMuslim[lang] :txtWording.openingInvitationGeneral[lang]}
                </>
              : 
                <>
                  {MUSLIM_INVITATION ? txtWording.openingAnnuncementMuslim[lang] : txtWording.openingAnnuncementGeneral[lang]}
                </>
            }
          </Text>
        </WithAnimation>
        <Box height={HEIGHT_OVERFLOW} />
        {/* BOX with Invert Colors */}
        {IS_BOY_FIRST
          ? 
            <>
              <Box bgColor="mainColor">
                <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
                  <WithAnimation>
                    <DetailCard
                      fullName={"dr. I Made Surya <br />Budikusuma, S.Ked"}
                      fullNameProps={{ ...COMMON_OPTIONS.fullNameProps }}
                      parentInfo={BOY_PARENT_NAME}
                      instagramId={IG_BOY}
                      imgSrc={IMG_MAN}
                      instagramIdProps={{ ...COMMON_OPTIONS.instagramButton, colorScheme: 'blackAlpha' }}
                    />
                  </WithAnimation>
                </Box>
                <Box height={`calc(${HEIGHT_OVERFLOW} - 16px)`} />
              </Box>
              <Box>
                <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
                  <WithAnimation>
                    <DetailCard
                      imgSrc={IMG_GIRL}
                      fullName={`Ketut Chandra <br />Ratnasari, S.Tr.Log`}
                      fullNameProps={{ ...COMMON_OPTIONS.fullNameProps, color: 'mainColorText' }}
                      parentInfo={GIRL_PARENT_NAME}
                      parentInfoProps={{ color: 'mainColorText' }}
                      instagramId={IG_GIRL}
                      instagramIdProps={{ ...COMMON_OPTIONS.instagramButton, backgroundColor: 'mainColor' }}
                    />
                  </WithAnimation>
                </Box>
              </Box>
            </>
          :
            <>
              <Box bgColor="mainColor">
                <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
                  <WithAnimation>
                    <DetailCard
                      fullName={GIRL_FULL_NAME}
                      fullNameProps={{ ...COMMON_OPTIONS.fullNameProps }}
                      parentInfo={GIRL_PARENT_NAME}
                      instagramId={IG_GIRL}
                      imgSrc={IMG_GIRL}
                      instagramIdProps={{ ...COMMON_OPTIONS.instagramButton, colorScheme: 'blackAlpha' }}
                    />
                  </WithAnimation>
                </Box>
                <Box height={`calc(${HEIGHT_OVERFLOW} - 16px)`} />
              </Box>
              <Box>
                <Box padding="0px 32px" transform={`translateY(-${HEIGHT_OVERFLOW})`}>
                  <WithAnimation>
                    <DetailCard
                      imgSrc={IMG_MAN}
                      fullName={BOY_FULL_NAME}
                      fullNameProps={{ ...COMMON_OPTIONS.fullNameProps, color: 'mainColorText' }}
                      parentInfo={BOY_PARENT_NAME}
                      parentInfoProps={{ color: 'mainColorText' }}
                      instagramId={IG_BOY}
                      instagramIdProps={{ ...COMMON_OPTIONS.instagramButton, backgroundColor: 'mainColor' }}
                    />
                  </WithAnimation>
                </Box>
              </Box>
            </>
        }
      </Box>
    </Box>
  );
}

export default React.memo(CoupleInfo);