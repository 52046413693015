import React, { useState } from 'react';
import { func } from 'prop-types';
import { Box, Heading, Center } from '@chakra-ui/react';
import { GiPeaceDove } from 'react-icons/gi';

import ScrollToDown from '@/components/Common/ScrollToDown/V1';
import WithAnimation from '@/components/Common/WithAnimation';
import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';

import CopyRight from '@/components/Section/CopyRight';
import useInvitation from '@/hooks/useInvitation';
import txtWording from './locales';

import { GIRL_NAME_SHORT, BOY_NAME_SHORT, IS_BOY_FIRST } from '@/constants';
import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';
import { BG_SECONDARY } from '@/constants/colors';
import { styCoverV4 } from './styles';

/**
 * Function to render Component CoverV4
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function CoverV4({ onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const isInvitation = useInvitation();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  return (
    <Box minHeight="100vh" width="100%" pos="relative" backgroundColor={BG_SECONDARY}>
      <div css={styCoverV4}>
        <Box display="block">
          <Box>
            <Center paddingTop="calc(10%)" marginBottom="16px">
              <WithAnimation>
                <Center>
                  <GiPeaceDove color="#fff" size="60px" />
                </Center>
              </WithAnimation>
            </Center>
            <Center>
              <WithAnimation>
                <Heading
                  color="white"
                  textTransform="uppercase"
                  fontSize="14px"
                  fontWeight="300"
                  marginBottom="16px"
                  fontFamily="body"
                >
                  {isInvitation ? `The Wedding of` : `Wedding Announcement of`}
                </Heading>
              </WithAnimation>
            </Center>
            <Center marginTop="-12px">
              <WithAnimation>
                <Heading fontSize="4xl" color="white">
                  {IS_BOY_FIRST ? (
                    <>
                      {BOY_NAME_SHORT} {txtWording.conjunction[lang]} {GIRL_NAME_SHORT}
                    </>
                  ) : (
                    <>
                      {GIRL_NAME_SHORT} {txtWording.conjunction[lang]} {BOY_NAME_SHORT}
                    </>
                  )}
                </Heading>
              </WithAnimation>
            </Center>
          </Box>
          <Box pos="absolute" width="100%" bottom="140px">
            <Center>
              <ScrollToDown
                onClick={handleClickDetail}
                loading={loading}
                text={isInvitation ? txtWording.textButton[lang] : txtWording.textAnnouncement[lang]}
              />
            </Center>
          </Box>
        </Box>
      </div>
      <CopyRight
        withSong={false}
        withFlatIcon={false}
        transformWave="translateY(-4rem)"
        transformBody="translateY(calc(-4rem - 2px))"
        fontSize="small"
      />
    </Box>
  );
}

CoverV4.propTypes = {
  onSeeDetail: func.isRequired,
};

export default CoverV4;