import get from '@invitato/helpers/dist/getLocale';

export default {
  textDear: get('Kepada Bapak/Ibu/Saudara/i,', 'Dear Mr/Mrs/Ms,'),
  guest: get('Keluarga & Teman-teman', 'Family & Friends'),
  welcomingAnnuncement: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Tanpa mengurangi rasa hormat, kami mohon maaf tidak bisa mengundang Bapak/Ibu/Saudara/i secara langsung dalam acara pernikahan kami. Tetapi kami tetap memohon doa restu untuk keberlangsungan acara pernikahan kami.', 
    'We are pleased to announce our upcoming wedding to the family and friends. Our apologize to our loved ones who we wished we could have invited. Considering the pandemic restriction, we are unable to invite you in person. Please know that your presence will be sincerely missed and we hope that you will be able to celebrate with us from afar. Wish you and your family are staying safe and healthy.'),
  welcomingInvitation: get(
    'Sebuah kehormatan bagi kami dapat mengumumkan kabar bahagia pada hari ini. Besar harapannya, Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam hari pernikahan kami.', 
    'We are pleased to announce and invite you to our wedding. We sincerely hope that you will be able to attend and pray directly on our wedding day. Your presence will mean a lot to us.'),
};