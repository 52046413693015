import React from 'react';
import { Box, Text, Divider, Center } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';
import txtWording from './locales';

import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';
import { BG_PATTERN, BG_AYAT } from '@/constants/assets';

/**
 * function to render ayat section with Ayat content
 * @returns {JSX.Element}
 */
function AyatSection() {
  return (
    <Box padding="42px 32px" style={{ backgroundImage: `url(${BG_PATTERN})` }}>
      <Box p="24px 16px" bg="mainColor" borderRadius="16px">
        <Box marginBottom="32px">
          <Center>
            <WithAnimation>
              <Image src={BG_AYAT} borderTopRadius="48%" borderBottomRadius="8px" height="300px" />
            </WithAnimation>
          </Center>
        </Box>
        <WithAnimation>
          <Text align="center" fontSize="xs" color="mainColorTextLight" fontStyle="italic">
            {txtWording.textAyat[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text align="center" color="mainColorTextLight" marginTop="8px" fontSize="sm" fontWeight="bold">
            {txtWording.ayatName[lang]}
          </Text>
        </WithAnimation>
        <Center>
          <Divider width="80px" bg="mainColorTextLight" borderBottom="none" height="1px" margin="18px 0 18px" />
        </Center>
        <WithAnimation>
          <Text align="center" fontSize="xs" color="mainColorTextLight" fontStyle="italic">
            {`Ihaiva stam mā vi yaustam, Visvām āyur vyasnutam. Krindantau putrair naptrbhih, Modamānau sve grhe.`}
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Text align="center" color="mainColorTextLight" marginTop="8px" fontSize="sm" fontWeight="bold">
            {`(Rgveda: X.85.42)`}
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(AyatSection);