import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { string, bool } from 'prop-types';
import { Box, Center, Text, Button, Stack, useDisclosure } from '@chakra-ui/react';
import { BiBuildingHouse } from 'react-icons/bi';

import useInvitation from '@/hooks/useInvitation';

import { DEFAULT_LANGUAGE as lang } from '@/constants/features-flag';
import {
  EPOCH_END_EVENT,
  EPOCH_RESEPSI_START,
  GOOGLE_MAPS_ADDRESS,
  GOOGLE_MAPS_LINK,
  THE_BRIDE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
} from '@/constants';
import { TEXT_PRIMARY_LIGHT } from '@/constants/colors';

import AdditionalInformation from './AdditionalInformation';
import txtWording from './locales';

/**
 * extend UTC Time format
 */
dayjs.extend(utc);

function WeddingLocation({ color, withIcon, withLocation, withRoad }) {
  const { isOpen, onClose } = useDisclosure();
  const isInvitation = useInvitation();

  const handleClickGoogleMaps = () => {
    window.open(GOOGLE_MAPS_LINK, '_blank');
  };

  /**
   * Generate link for Google Calendar
   * @returns {void}
   */
  const handleClickAddToCalendar = () => {
    const title = `Wedding of ${THE_BRIDE} by Invitato.net`;
    const finalURL = `text=${encodeURIComponent(title)}`;
    const location = `location=${GOOGLE_MAPS_ADDRESS}`;

    const startDate = dayjs(EPOCH_RESEPSI_START * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');
    const endDate = dayjs(EPOCH_END_EVENT * 1000)
      .utc(true)
      .format('YYYYMMDDTHHmmss');

    window.open(
      `https://www.google.com/calendar/render?action=TEMPLATE&${finalURL}&dates=${startDate}/${endDate}&${location}`,
      '_blank',
    );
  };

  return (
    <Box>
      {withIcon && (
        <Center>
          <BiBuildingHouse size="40px" color={color} />
        </Center>
      )}
      {withLocation && (
        <Center>
          <Text fontSize="xl" fontWeight="bold" color={TEXT_PRIMARY_LIGHT}>
            {WEDDING_RESEPSI_LOC_NAME}
          </Text>
        </Center>
      )}
      {withRoad && (
        <Box>
          <Center>
            <Text
              fontSize="md"
              align="center"
              color={color}
              dangerouslySetInnerHTML={{ __html: WEDDING_RESEPSI_LOC_NAME }}
            />
          </Center>
          <Center>
            <Text color={color} fontSize="sm" align="center">
              {WEDDING_RESEPSI_LOC_ROAD}
            </Text>
          </Center>
        </Box>
      )}
      {isInvitation && (
        <Center m="5">
          <Stack direction="row" spacing={4} align="center">
            <Button
              size="xs"
              onClick={handleClickAddToCalendar}
              colorScheme="orange"
              className="animation-heart"
            >
              {txtWording.reminder[lang]}
            </Button>
            <Button
              size="xs"
              className="animation-heart"
              colorScheme="orange"
              onClick={handleClickGoogleMaps}
            >
              {txtWording.location[lang]}
            </Button>
          </Stack>
        </Center>
      )}
      <AdditionalInformation isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

WeddingLocation.propTypes = {
  color: string,
  withIcon: bool,
  withLocation: bool,
  withRoad: bool,
};

WeddingLocation.defaultProps = {
  color: 'black',
  withIcon: true,
  withLocation: true,
  withRoad: true,
};

export default WeddingLocation;
