import React, { useState } from 'react';
import { RiMailSendFill } from 'react-icons/ri';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
  Textarea,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';
import useMutation from '@/hooks/useMutation';
import usePartner from '@/hooks/usePartner';
import useGuestInfo from '@hooks/useGuestInfo';

import { API_HOSTNAME, API_RSVP_TABLE } from '@/constants';
import { ENABLE_GUEST_PERSONALIZATION, DEFAULT_LANGUAGE as lang, } from '@/constants/features-flag';

import { txtTitle, txtForm } from './locales';

const TYPE = {
  YES: 'YA',
  NO: 'TIDAK',
};

const ERROR_TYPE = {
  name: undefined,
  email: undefined,
  phone: undefined,
  address: undefined,
};

const INPUT_COMMON_PROPS = {
  borderRadius: 'md',
  isRequired: true,
  size: 'sm',
  type: 'text',
  variant: 'outline',
  backgroundColor: 'white',
  color: 'mainColorText',
  _placeholder: { color: 'mainColorText' },
};

const SELECT_COMMON_PROPS = {
  size: 'sm',
  variant: 'outline',
  backgroundColor: 'white',
  color: 'mainColorText',
};

const enc = (s) => encodeURIComponent(s);

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ ...rest }) {
  const toast = useToast();
  const { name: guestName, shift } = useGuestInfo();
  const partner = usePartner();

  const [onSentConfirmation, loading] = useMutation(API_HOSTNAME, API_RSVP_TABLE, 'insert');

  const [name, setName] = useState(() => guestName);
  const [phone, setPhone] = useState('-');
  const [address, setAddress] = useState('-');
  const [attended, setAttended] = useState(TYPE.YES);
  const [bringPartner, setBringPartner] = useState(1);
  const [errorType, setErrorType] = useState(ERROR_TYPE);

  /**
   * function to set state
   * @param {object} e - html event value
   * @param {function} setState
   * @param {string} typeState
   * @returns
   */
  const onSetState = (e, setState, typeState) => {
    const value = e.target.value;
    setState(value);

    if (typeState === 'email') {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
        setErrorType({ ...errorType, email: txtForm.invalidEmail[lang] });
        return;
      }
    }
    setErrorType(ERROR_TYPE);
  };

  /**
   * function to submit to BE with check the form value first
   */
  const onSubmitForm = async () => {
    if (errorType.name || errorType.phone || errorType.address) return;

    if (!name || !phone || !address) {
      setErrorType({
        name: !name ? txtForm.required[lang] : '',
        phone: !phone ? txtForm.required[lang] : '',
        address: !address ? txtForm.required[lang] : '',
      });

      return;
    }

    const query =
      `nama=${enc(name)}` +
      `&hp=${phone}` +
      `&hadir=${attended}` +
      `&jumlah_tamu=${attended === TYPE.NO ? '' : bringPartner}` +
      `&alamat=${address}` +
      `&shift=${enc(`SHIFT ${shift}`)}`;

    const result = await onSentConfirmation(query);

    if (result.success) {
      toast({
        title: txtForm.success[lang],
        description: txtForm.msgSuccess[lang],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setName('');
      setPhone('');
      setAddress('');
    } else {
      toast({
        title: 'Oops!',
        description: txtForm.msgError[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box minHeight="300px" padding="24px 16px 0 16px" {...rest}>
      <Box padding="24px 0 12px 0">
        <WithAnimation>
          <Text align="center" color="mainColorText" fontSize="sm">
            {txtTitle[lang]}
          </Text>
        </WithAnimation>
      </Box>
      <WithAnimation>
        <Box
          backgroundColor="secondaryColorText"
          borderRadius="2xl"
          marginTop="16px"
          padding="24px"
        >
          <FormControl margin="8px 0" isInvalid={errorType.name}>
            <Input
              {...INPUT_COMMON_PROPS}
              placeholder={txtForm.name[lang]}
              value={name}
              onChange={(e) => onSetState(e, setName)}
            />
            <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
          </FormControl>

          {false && (
            <FormControl margin="8px 0" isInvalid={errorType.phone}>
              <Input
                {...INPUT_COMMON_PROPS}
                placeholder={txtForm.hp[lang]}
                value={phone}
                onChange={(e) => onSetState(e, setPhone, 'phone')}
              />
              <FormErrorMessage marginTop="4px">{errorType.phone}</FormErrorMessage>
            </FormControl>
          )}

          {false && (
            <FormControl margin="8px 0" isInvalid={errorType.address}>
              <Textarea
                {...INPUT_COMMON_PROPS}
                height="40px"
                placeholder={txtForm.address[lang]}
                value={address}
                onChange={(e) => onSetState(e, setAddress)}
              />
              <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
            </FormControl>
          )}

          <FormControl margin="8px 0">
            <FormLabel fontSize="sm" color="white" margin="8px 0">
              {txtForm.willYoutAttend[lang]}
            </FormLabel>
            <Select
              {...SELECT_COMMON_PROPS}
              value={attended}
              onChange={(e) => onSetState(e, setAttended)}
            >
              <option value={TYPE.YES} color="white" style={{ color: 'black' }}>
                {txtForm.willAttend[lang]}
              </option>
              <option value={TYPE.NO} style={{ color: 'black' }}>
                {txtForm.noAttend[lang]}
              </option>
            </Select>
          </FormControl>

          {attended === TYPE.YES && false && (
            <>
              <FormControl>
                <FormLabel fontSize="sm" color="mainColorText" margin="8px 0">
                  {txtForm.willYouBringPartner[lang]}
                </FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={bringPartner}
                  onChange={(e) => onSetState(e, setBringPartner)}
                >
                  {ENABLE_GUEST_PERSONALIZATION
                    ?
                      <>
                        {!partner && (
                          <option value={1} style={{color: 'black'}}>1</option>
                        )}
                        {partner === 1 && (
                          <option value={1} style={{color: 'black'}}>1</option>
                        )}
                        {partner === 2 && (
                          <>
                            <option value={1} style={{color: 'black'}}>1</option>
                            <option value={2} style={{color: 'black'}}>2</option>
                          </>
                        )}
                        {partner === 3 && (
                          <>
                            <option value={1} style={{color: 'black'}}>1</option>
                            <option value={2} style={{color: 'black'}}>2</option>
                            <option value={3} style={{color: 'black'}}>3</option>
                          </>
                        )}
                        {partner === 4 && (
                          <>
                            <option value={1} style={{color: 'black'}}>1</option>
                            <option value={2} style={{color: 'black'}}>2</option>
                            <option value={3} style={{color: 'black'}}>3</option>
                            <option value={4} style={{color: 'black'}}>4</option>
                          </>
                        )}
                        {partner > 4 && (
                          <>
                            <option value={1} style={{color: 'black'}}>1</option>
                            <option value={2} style={{color: 'black'}}>2</option>
                            <option value={3} style={{color: 'black'}}>3</option>
                            <option value={4} style={{color: 'black'}}>4</option>
                            <option value={5} style={{color: 'black'}}>5</option>
                          </>
                        )}
                      </>
                    :
                      <>
                        <option value={1} style={{color: 'black'}}>1</option>
                        <option value={2} style={{color: 'black'}}>2</option>
                      </>
                  }
                </Select>
                {bringPartner === TYPE.YES && false && (
                  <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                    *) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang partner
                    atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.
                  </FormHelperText>
                )}
              </FormControl>
            </>
          )}
          <Center>
            <Button
              color="white"
              fontWeight="medium"
              isLoading={loading}
              leftIcon={<RiMailSendFill />}
              marginTop="16px"
              size="sm"
              type="button"
              variant="outline"
              borderColor="white"
              _hover={{
                background: 'white',
                color: 'mainColorText'
              }}
              onClick={onSubmitForm}
            >
              {txtForm.submit[lang]}
            </Button>
          </Center>
        </Box>
      </WithAnimation>
    </Box>
  );
}

export default React.memo(RSVPSection);