import React from 'react';
import { Box, Heading, Center, Text, AspectRatio, Button, Link } from '@chakra-ui/react';

import Wave from '@components/Common/Wave';
import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';

import getChackra from '@invitato/helpers/dist/getChackra';
import txtWording from './locales';

import { DEFAULT_LANGUAGE as lang, ENABLE_VIDEO_PREWEDDING } from '@/constants/features-flag';
import { THE_BRIDE, YOUTUBE_EMBED, YOUTUBE_LINK, DISABLE_IFRAME } from '@/constants';
import { COVER_YOUTUBE, BG_YOUTUBE } from '@/constants/assets';

function YoutubeLiveSection({ ...rest }) {
  return (
    <Box>
      <Box p={8} {...rest}>
        <WithAnimation>
          <Center>
            <Heading color="theme.bgSecondary" fontFamily="fantasy">
              {!ENABLE_VIDEO_PREWEDDING ? txtWording.title[lang] : txtWording.titlePrewedding[lang]}
            </Heading>
          </Center>
          <Text align="center" fontSize="lg">
            {THE_BRIDE}
          </Text>
        </WithAnimation>
      </Box>
      <Box h={'48px'} />
      <Wave
        isElips
        width="100%"
        transform="translateY(-8px)"
        backgroundColor="transparent"
        position="absolute"
      />
      <Box
        background={`url(${BG_YOUTUBE})`}
        boxShadow={`inset 0 0 0 2000px ${getChackra('bgSecondaryTransparent')}`}
        backgroundSize="cover"
        backgroundPosition="center"
        paddingBottom="32px"
      >
        <Box p={8} paddingBottom="0">
          <WithAnimation>
            <AspectRatio
              maxW="560px"
              ratio={16 / 9}
              borderRadius="lg"
              transform="translateY(-80px)"
              marginBottom={!ENABLE_VIDEO_PREWEDDING ? '' : '-32px'}
            >
              {DISABLE_IFRAME ? (
                <Image src={COVER_YOUTUBE} objectFit="cover" alt="youtube" borderRadius="lg" />
              ) : (
                <iframe
                  title={`Live wedding of ${THE_BRIDE}`}
                  src={YOUTUBE_EMBED}
                  allowFullScreen
                  loading="lazy"
                  style={{ borderRadius: '16px', border: `8px solid ${getChackra('bgSecondary')}` }}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
            </AspectRatio>
          </WithAnimation>
        </Box>
        {!ENABLE_VIDEO_PREWEDDING && (
          <Text marginTop="-70px" color="white" align="center" fontSize="sm" padding="16px 32px">
            <span dangerouslySetInnerHTML={{ __html: txtWording.desc[lang] }} />
            <br />
            <Link href={YOUTUBE_LINK} target="_blank">
              <Button size="xs" className="animation-heart" colorScheme="orange" margin="8px">
                {txtWording.textButton[lang]}
              </Button>
            </Link>
          </Text>
        )}
      </Box>
    </Box>
  );
}

export default React.memo(YoutubeLiveSection);
